import { FC } from 'react';
import { RichText, RichTextProps } from 'prismic-reactjs';
import { Elements } from 'prismic-reactjs';

import { ChildrenRenderer } from '@lib/renderers/ChildrenRenderer';
import { TEXT_COLOR_LABELS } from '@lib/slice-machine/constants';
import { linkResolver } from '@lib/slice-machine/linkResolver';
import { SxStyles } from '@lib/theme/sxTheme';
import { SxTheme } from '@lib/theme/types';
import { Box, Theme, useTheme } from '@mui/system';
import { LinkButton, LinkButtonProps } from '@ui/buttons/LinkButton';

interface Props extends Omit<RichTextProps, 'linkResolver'> {
  linkBtnProps?: {
    linkAsButton?: boolean;
  } & Partial<LinkButtonProps>;
  containerSx?: SxTheme;
}

export const CustomRichText: FC<Props> = (props) => {
  const { linkBtnProps = {}, containerSx, ...rest } = props;
  const theme = useTheme();

  return (
    <Box sx={styles.merge('container', containerSx)}>
      <RichText
        htmlSerializer={(type, element, content, children, key) =>
          htmlSerializer(
            type,
            element,
            content,
            children,
            key,
            linkBtnProps,
            theme,
          )
        }
        linkResolver={linkResolver}
        {...rest}
      />
    </Box>
  );
};

const htmlSerializer = (
  type: Elements,
  element: any,
  content: any,
  children: any[],
  key: string,
  linkBtnProps: Props['linkBtnProps'] = {},
  theme: Theme,
) => {
  if (
    type === Elements.label &&
    TEXT_COLOR_LABELS.includes(element?.data?.label)
  ) {
    return (
      <ChildrenRenderer
        key={key}
        Wrapper={Box}
        wrapperProps={{
          sx: {
            color: element?.data?.label,
            [theme.breakpoints.down('sm')]: {
              fontSize: '20px',
            },
          },
          component: 'span',
        }}
      >
        {children}
      </ChildrenRenderer>
    );
  } else if (type === Elements.hyperlink) {
    const { linkAsButton = false, ...restLinkBtnProps } = linkBtnProps;
    const href = linkResolver(element.data);

    if (linkAsButton) {
      return (
        <LinkButton
          key={key}
          href={href}
          variant="contained"
          target={element.data.target}
          {...restLinkBtnProps}
        >
          {content}
        </LinkButton>
      );
    }
  }

  return null;
};

const styles = new SxStyles({
  container: {
    '& * a': {
      textDecoration: 'none',
    },
    '& p, & ol, & ul': {
      mb: 0,
      lineHeight: 1.3,
    },
    '& > p:first-of-type, & > ol:first-of-type, & > ul:first-of-type': {
      mt: 0,
    },
  },
});
